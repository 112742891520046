
import { useLocation } from 'react-router-dom';

const ErrorElement = () => {
    let h = window.screen.availHeight;
    const location = useLocation()
  return (
    <div className="col-lg-12 mb-4">
        <div className="empty-section-top-box" style={{ height: h / 2 }}>
        <div
          className="empty-section-inner-box"
          style={{ marginTop: h / 4 - 25 }}
        >
          <p className="empty-page-text">Something wrong</p>
          <a
            href={`${location.pathname}`}
            style={{ color: "white" }}
            className="elm-primary-btn"
          >
            Reload
          </a>
        </div>
      </div>
    </div>
  )
}

export default ErrorElement