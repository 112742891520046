import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";

const initialState = {
  loadingReview: true,
  loadingAvgReview: true,
  avgRating: 0,
  reviews: {
    pagination: {
      isLoading: false,
      total: 0,
      pageNo: 1,
      isNext: false,
    },
    data: [],
  },
  error : ""
};

export const fetchAvgRating = createAsyncThunk(
  "getAvgRating",
  async ({itemId, itemType} : {itemId : number, itemType : number}) => {
    try {
      let response = await fetchDetails(`webinars/get-average-rating`, 2 ,{itemId, itemType} );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchAllReview = createAsyncThunk("getAllReview", async ({pageNo, itemId, itemType} : {pageNo : number, itemId : number, itemType : number}) => {
  try {
    let response = await fetchDetails(`webinars/get-all-reviews/${pageNo}`,2 , {itemId, itemType});
    
    return response;
  } catch (error) {
    console.log(error);
  }
});
export const reviewSlice = createSlice({
    name: "webinar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvgRating.pending, (state) => {
        state.loadingAvgReview =  true;
      })
      .addCase(fetchAvgRating.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode
        if(responseCode === 200 && action.payload){
            state.avgRating = action.payload.response
        }
        
        state.loadingAvgReview = false;
      })
      .addCase(fetchAvgRating.rejected, (state, action) => {
         state.loadingAvgReview = false;
        // state.error = action.error.message
        state.error = "Something is wrong"
      })
      .addCase(fetchAllReview.pending,(state) =>{
        state.loadingReview = true
      })
      .addCase(fetchAllReview.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode
        if(responseCode === 200 && action.payload){
            state.reviews.data = action.payload.response
        }
        state.loadingReview = false
      })
      .addCase(fetchAllReview.rejected, (state, action) => {
        state.loadingReview = false;
        //state.error = action.error.message
        state.error = "Something is wrong"
      })
    }
})

export const { } =
  reviewSlice.actions;
