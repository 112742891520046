import { configureStore } from '@reduxjs/toolkit'
import { webinarSlice } from '../services/webinarSlice'
//import { productSlice } from '../services/productSlice'
import { courseSlice } from '../services/courseSlice'
import { errorSlice } from '../services/errorSlice'
import { userSlice } from '../services/userSlice'
import { reviewSlice } from '../services/reviewSlice'



export const store = configureStore({
  reducer:{
    courseSlice : courseSlice.reducer,
    webinarSlice: webinarSlice.reducer,
    errorSlice : errorSlice.reducer,
    userSlice : userSlice.reducer,
    reviewSlice : reviewSlice.reducer
   // [productSlice.reducerPath] : productSlice.reducer,
  },
  // middleware:(getDefaultMiddleware)=>getDefaultMiddleware().concat(productSlice.middleware)
})


export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
